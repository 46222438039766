import { XF } from "./XF";

// when one tap is shown, we also show an empty overlay to act as a scrim

let overlay: (typeof XF)["Overlay"] | undefined;

window.addEventListener(
  "message",
  (event) => {
    if (event.origin !== window.location.origin) {
      return;
    }

    switch (event.data) {
      case "ShowOneTapOverlay":
        overlay?.hide();
        overlay = XF.showOverlay(XF.getOverlayHtml(""));
        break;
      case "GoogleOneTapClose":
        overlay?.hide();
        overlay = undefined;
        break;
      default:
        break;
    }
  },
  false
);
